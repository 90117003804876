/* src/fonts.css */
@font-face {
  font-family: "Calibri";
  src: url("./fonts/Calibri-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Calibri";
  src: url("./fonts/Calibri-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: bold;
}

:root {
  --font-calibri: "Calibri", sans-serif;
}

/* Apply font family globally */
body {
  font-family: var(--font-calibri);
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.imprima-regular {
  font-family: "Imprima", sans-serif;
  font-weight: 200;
  font-style: normal;
}
.inter-regular {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}
.younme-shadow {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
}

:root {
  --background: #ffffff;
  --foreground: #171717;
  --primary-color: #60304f; /* Title color */
  --secondary-color: #949494; /* Paragraph color */
  --link-color: #0088b4; /* Link text color */
  --highlight-color: #58a629; /* Highlight color */
}

@media (prefers-color-scheme: dark) {
  :root {
    --background: #0a0a0a;
    --foreground: #ededed;
  }
}

body {
  color: var(--foreground);
  background: var(--background);
  font-family: var(--font-calibri), sans-serif;
}

/* Custom Classes */
.heading-text {
  font-family: var(--font-calibri), sans-serif;
}
.title {
  color: var(--primary-color);
  font-family: var(--font-calibri), sans-serif;
  font-weight: bold;
}

.paragraph {
  color: var(--secondary-color);
  font-family: var(--font-calibri), sans-serif;
  margin-bottom: 15px;
  white-space: pre-wrap;
}

.link-text {
  color: var(--link-color);
  text-decoration: underline;
  transition: color 0.3s ease;
}

.link-text:hover {
  color: var(--highlight-color);
}

.highlight {
  color: var(--highlight-color);
  font-family: var(--font-calibri), sans-serif;
}

/* Utility for text balance */
@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}
